<template>
  <el-row class="home-container">
    <el-container>
        <el-header style="height:unset">
            <div class="header-block">
              <p class="logo-block">
                <a href="#LOGO"><img class="logo-img" src="@/assets/images/head_logo.png" alt="">RealTimeGTO</a>
              </p>
                <ul class="nav-ul"> 
                  <li class="nav-li" v-for="(item,index) in menuList" :class="{'is-active': index === currentNavNum}" :key="index" @click="currentNavNum = index">
                    <a :href="item.href">{{item.label}}</a>
                  </li>
                </ul>
                <div class="header-right">
                  <span class="toApp-button down" @click="downloadApp">Download</span>
                  <!-- <a class="toApp-button" :href="`${lywsConfig.gatewayUrl}sign`">Download</a> -->
                  <el-dropdown trigger="click" placement="bottom-start" @command="handleDropdown">
                    <span class="el-dropdown-link">
                      <span class="lang-text">{{localeList.find(item => item.value === currentLocale)?.name}}</span>
                      <i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <template v-for="(item,index) in localeList">
                        <el-dropdown-item :key="index" :command="item.value" v-if="item.value !== currentLocale">
                          <span class="lang-text">{{item.name}}</span>
                        </el-dropdown-item>
                      </template>
                    </el-dropdown-menu>
                  </el-dropdown>
                  <router-link class="toApp-button" to="/sign" tag="a">登录/注册</router-link>
                </div>
            </div>
        </el-header>
        <el-main>
            <div class="content-wrapper">
              <div class="logo__container content-item" id="LOGO">
                <div class="logo_table_wrapper">
                  <div class="logo_table_block">
                    <img class="logo-img" src="@/assets/images/logo_view.png" alt="">
                  </div>
                  <div class="logo_desc_wrapper">
                    <p class="logo_desc_row" :class="`logo_desc_row${index + 1}`" v-for="(item,index) in infoData.improveActionVOS" :key="index">
                      <span class="row_left">{{item.actionName}}
                        <span class="row_left_bg"></span>
                      </span>
                      <span class="row_center">{{item.improvePercent}}</span>
                      <span class="row_right">{{item.improveValue}}</span>
                    </p>
                  </div>
                </div>
              </div>
              <div class="apps__container content-item" id="App">
                <div class="apps_left apps_item">
                  <img class="apps_img" src="@/assets/images/apps_left_img.png" alt="">
                  <div class="apps_mobile">
                    <img class="apps_mobile_img" src="@/assets/images/apps_left_mobile_img.png" alt="">
                  </div>
                </div>
                <div class="apps_right apps_item">
                  <h2 class="apps_title">{{infoData.appInfoVO?.appTitle}}</h2>
                  <p class="apps_description">{{infoData.appInfoVO?.appContent}}</p>
                  <div class="apps_buttons">
                    <span>Available on</span>
                    <div class="buttons-block">
                      <p @click="downloadApp">
                        <img src="@/assets/images/apps_windows.png" alt="">
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="view__container content-item" id="Features">
                <div class="view_left feature_item">
                  <div class="feature_title">
                    <img src="@/assets/images/viewer-icon.png" alt="">
                    <h2>View</h2>
                  </div>
                  <h3 class="feature_subtitle">{{infoData.viewInfoVO?.viewTitle}}</h3>
                  <div class="feature_text">
                    <p>{{infoData.viewInfoVO?.viewContent}}</p>
                  </div>
                </div>
                <div class="view_right feature_item feature_pic">
                  <el-carousel :interval="5000" arrow="always">
                    <el-carousel-item v-for="(item,index) in infoData.viewInfoVO?.imageUrlList || []" :key="index">
                      <img :src="item" alt="">
                    </el-carousel-item>
                  </el-carousel>
                </div>
              </div>
              <div class="content-item trainer__container row_reverse">
                <div class="feature_item">
                  <div class="feature_title">
                    <img src="@/assets/images/trainer-icon.png" alt="">
                    <h2>Trainer</h2>
                  </div>
                  <h3 class="feature_subtitle">{{infoData.trainerInfoVO?.trainerTitle}}</h3>
                  <div class="feature_text">
                    <p>{{infoData.trainerInfoVO?.trainerContent}}</p>
                  </div>
                </div>
                <div class="feature_item feature_pic">
                  <el-carousel :interval="5000" arrow="always">
                    <el-carousel-item v-for="(item,index) in infoData.trainerInfoVO?.imageUrlList || []" :key="index">
                      <img :src="item" alt="">
                    </el-carousel-item>
                  </el-carousel>
                </div>
              </div>
              <div class="content-item hands__container">
                <div class="view_left feature_item">
                  <div class="feature_title">
                    <img src="@/assets/images/hands-icon.png" alt="">
                    <h2>Hands</h2>
                  </div>
                  <h3 class="feature_subtitle">{{infoData.handsInfoVO?.handsTitle}}</h3>
                  <div class="feature_text">
                    <p>{{infoData.handsInfoVO?.handsContent}}</p>
                  </div>
                  <div class="feature-brand">
                    <div class="brand-item" :class="item.class" v-for="(item,index) in brandList" :key="index">
                      <img :src="item.url" alt="">
                    </div>
                  </div>
                </div>
                <div class="view_right feature_item feature_pic">
                  <el-carousel :interval="5000" arrow="always">
                    <el-carousel-item v-for="(item,index) in infoData.handsInfoVO?.imageUrlList || []" :key="index">
                      <img :src="item" alt="">
                    </el-carousel-item>
                  </el-carousel>
                </div>
              </div>
              <div class="content-item price__container" id="Prices">
                <div class="price_block">
                  <h2 class="price_title">Spots/Prices</h2>
                  <div class="price_wrapper">
                    <ul class="price_ul">
                      <li class="price_li" :class="{'is-active':currentPrice === index }" v-for="(item,index) in infoData.spotsInfoVOS" :key="index" @click="currentPrice = index">
                        {{item.spotsCode}}
                      </li>
                    </ul>
                    <div class="priceCard_wrapper">
                      <div class="priceCard_block">
                        <div class="price_card" v-for="(item,index) in infoData.spotsInfoVOS?.[currentPrice]?.itemVOS || []" :key="index">
                          <h2 class="card_title">{{item.spotsTitle}}</h2>
                          <p class="card_subtitle" :class="item.class">{{item.spotsBody}}</p>
                          <p class="card_stack">
                            Stack:<span>{{item.stackRange}}</span>
                          </p>
                        </div>
                      </div>
                      <div class="desc_block">
                        <p class="desc_p">{{infoData.spotsInfoVOS?.[currentPrice]?.spotsDesc}}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="pro_includes">
                  <div class="pro_left">
                    <h2 >Cutting Edge Technology</h2>
                  </div>
                  <div class="pro_center">
                      <div class="pro_phone">
                        <img src="@/assets/images/iPhone-13.svg" alt="">
                        <div class="pro_pic_block">
                          <div class="pro_pic">
                            <img src="@/assets/images/pro/pro_1.png" alt="">
                          </div>
                          <div class="pro_pic">
                            <img src="@/assets/images/pro/pro_2.png" alt="">
                          </div>
                          <div class="pro_pic">
                            <img src="@/assets/images/pro/pro_3.png" alt="">
                          </div>
                          <div class="pro_pic">
                            <img src="@/assets/images/pro/pro_4.png" alt="">
                          </div>
                          <div class="pro_pic">
                            <img src="@/assets/images/pro/pro_5.png" alt="">
                          </div>
                          <div class="pro_pic">
                            <img src="@/assets/images/pro/pro_6.png" alt="">
                          </div>
                        </div>
                      </div>
                  </div>
                  <ul class="pro_right">
                    <li>Unrivaled accurate</li>
                    <li>Extremely fast</li>
                    <li>Stunning experience</li>
                    <li>Delivered by PROs</li>
                  </ul>
                </div>
              </div>
              <!-- <div class="content-item reviews__container" id="Reviews">
                <h2 class="reviews_title">Reviews</h2>
                <div class="reviews_wrapper">
                  <el-carousel :interval="5000" arrow="always" :autoplay="false" type="card">
                    <el-carousel-item v-for="(item,index) in infoData.reviewInfoVOS" :key="index" class="carousel_item" :class="`carousel_item${index + 1}`">
                      <div class="reviews_item">
                        <div class="reviews_header">
                          <img class="reviews_img" :src="item.headImageUrl" alt="">
                        </div>
                        <p class="reviews_desc">{{item.content}}</p>
                        <p class="reviews_name">
                          <span class="name1">{{item.reviewUserName}}</span>
                          <span class="name2">{{item.reviewIdentity}}</span>
                        </p>
                      </div>
                    </el-carousel-item>
                  </el-carousel>
                </div>
              </div> -->
              <div class="content-item questions__container" id="Questions">
                <h2 class="questions_title">Questions?</h2>
                <div class="questions_content">
                  <el-collapse v-model="activeName" accordion>
                    <el-collapse-item v-for="(item,index) in infoData.questionInfoVOS" :key="index" :title="item.question" :name="index">
                      <div>{{item.answer}}</div>
                    </el-collapse-item>
                  </el-collapse>
                </div>
              </div>
            </div>
        </el-main>
        <el-footer style="height:100%">
            <div class="footer_wrapper">
              <div class="footer_block">
                <p class="footer__title">{{currentLocale === 'cn' ? '联系方式' : 'contact information'}}</p>
                <div class="footer_item">
                  <p class="social">
                    {{currentLocale === 'cn' ? '邮箱' : 'email'}}：{{infoData.contactVO?.email}}
                    <span>discord：<a :href="infoData.contactVO?.discord" target="_blank" rel="noopener noreferrer">{{infoData.contactVO?.discord}}</a></span>
                    <!-- <a href="support@simplepoker.com">{{infoData.contactVO?.email}}</a> -->
                  </p>
                  <div class="social__item">
                    <p class="social__item__p">
                      <!-- <img :src="infoData.contactVO?.qqGroupQrcode" alt=""> -->
                      <img src="@/assets/images/QQGroupQrcode.png" alt="">
                      <span>QQ群:{{infoData.contactVO?.qqGroup}}</span>
                    </p>
                    <p class="social__item__p">
                      <!-- <img :src="infoData.contactVO?.qqChannelQrcode" alt=""> -->
                      <img src="@/assets/images/QQChannelQRCode.png" alt="">
                      <span>QQ频道:{{infoData.contactVO?.qqChannel}}</span>
                    </p>
                    <p class="social__item__p">
                      <img :src="infoData.contactVO?.discordQrcode" alt="">
                      <!-- <span>discord:{{infoData.contactVO?.discord}}</span> -->
                      <span>discord</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
        </el-footer>
    </el-container>
  </el-row>
</template>

<script>
import lywsConfig from '@config/global';
import API from "@/api/home.js"
import {brandList} from '../data.js'
export default {
  components: {},
  provide(){
    return {
        initHome:this.initHome,
    }
  },
  data() {
    return {
      lywsConfig,
      currentNavNum:0,
      activeName:0,
      brandList,
      currentPrice:0,
      currentLocale: "cn",
      localeList: [
        { name: "中文", value: "cn" },
        { name: "EN", value: "en" },
      ],
      currentTheme: "默认主题",
      themeList: [
        { name: "默认主题", value: "default" },
        { name: "暗黑主题", value: "dark" },
      ],
      menuList:[
        {label:'APP',href:'#App'},
        {label:'Features',href:'#Features'},
        {label:'Prices',href:'#Prices'},
        // {label:'Reviews',href:'#Reviews'},
        {label:'F.A.Q.',href:'#Questions'},
      ],
      activeIndex:'0',
      currentEnpInfo: null,
      showToggleGroupVisible: false,
      showChildrenComp:true,
      infoData: {
        appInfoVO:{},
        handsInfoVO:{},
        improveActionVOS:[],
        spotsInfoVOS:[],
        questionInfoVOS:[],
        reviewInfoVOS:[],
        trainerInfoVO:{},
        viewInfoVO:{},
        contactVO:{}
      }
    };
  },
  async mounted() {
    await this.initData();
  },
  methods: {
    async initData() {
      let resData = await API.findInfoData({lang:this.currentLocale}).catch(() => {})
      if(resData.success) {
        let newData = resData.data || {}
        let {improveActionVOS,spotsInfoVOS,questionInfoVOS,reviewInfoVOS,} = newData
        this.infoData = {
          ...newData,
          improveActionVOS: improveActionVOS || [],
          spotsInfoVOS: spotsInfoVOS || [],
          questionInfoVOS: questionInfoVOS || [],
          reviewInfoVOS: reviewInfoVOS || [],
        }
      }
    },
    /**
     * @description: 切换中英文
     * @return {*}
     */
    changeLanguage(item) {
      this.currentLocale = item.name;
      let locale = item.value;
      this.$i18n.locale = locale;
      this.$actions.setGlobalState({
        i18n: {
          locale,
          i18nMessages: this.$i18n.getLocaleMessage(locale),
        },
      });
    },
    handleDropdown(value) {
      this.currentLocale = value;
      this.initData()
    },
    /**
     * @description: 切换主题
     * @return {*}
     */
    changeTheme(item) {
      this.currentTheme = item.name;
      window.document.body.setAttribute("data-theme", item.value);
    },
    initHome() {},
    downloadApp() {
      if(this.infoData?.appInfoVO?.windowsUrl) {
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = this.infoData.appInfoVO.windowsUrl;
        a.download = 'GTO';
        document.body.appendChild(a);
        a.click();
        // 移除元素
        document.body.removeChild(a);
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/home.scss';
</style>
<style>
@import '../../../src/assets/styles/home.css';
</style>
